<template>
  <h1></h1>
</template>

<script>
export default {
  data() {
    return {
      value9: 10
    };
  }

}
</script>

<style>

</style>